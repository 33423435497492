<template>
  <!-- <reportAdditionalFieldsTemplate
    v-model="reportTemplatevalue"
    :fonts="result.additional.fonts"
    :fontSizes="result.additional.fontSizes"
    :result="result"
  ></reportAdditionalFieldsTemplate>-->
  <div>
    <img
      style="display:inline-block;height:45px;max-width:524.5px;width:545px;"
      status="none"
      class="sourceHeaderImg required"
      :src="imageSource"
    />
    <momentDateFormatter :result="result" v-model="timeformat"></momentDateFormatter>

    <!-- <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="{}"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      addButtTitle="Add Field"
    ></lineFieldsArray>-->
    <reportAdditionalData :reportTemplatevalue="reportTemplatevalue" @input="updateReportTemplate"></reportAdditionalData>
    <input type="hidden" :value="JSON.stringify(reportTemplatevalue)" :name="field" />
  </div>
</template>

<script>
import momentDateFormatter from "@/components/legacy/momentDateFormatter.vue";
import reportAdditionalData from "@/components/legacy/reportAdditionalData.vue";
export default {
  components: { momentDateFormatter, reportAdditionalData },
  props: ["result", "fieldAttributes", "field"],
  data() {
    return { reportTemplatevalue: {} };
  },
  computed: {
    pairArray: {
      get() {
        let discFields = [];
        if (typeof this.reportTemplatevalue["discFields"] != "undefined") {
          discFields = this.reportTemplatevalue["discFields"];
        }
        return discFields;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "discFields", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    imageSource() {
      let imageSource = "";
      if (typeof this.reportTemplatevalue.headerImg != "undefined") {
        imageSource = this.reportTemplatevalue.headerImg.imgURL;
      }
      return imageSource;
    },
    timeformat: {
      get() {
        let timeformat = "DD.MM.YYYY HH:mm:ss";
        if (typeof this.reportTemplatevalue["timeformat"] != "undefined") {
          timeformat = this.reportTemplatevalue["timeformat"];
        }
        return timeformat;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "timeformat", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },

  },
  watch: {
    "result.valueLine.n_reportTemplateId": function (id) {
      this.getReportTemplateAjaxCall(id);
    },
  },
  methods: {
    updateReportTemplate(value) {
      this.reportTemplatevalue = value;
      this.$emit("input", this.reportTemplatevalue);
    },
    getReportTemplateAjaxCall(id) {
      //  var fieldsObject = $(that).closest(".fields");
      // var url = fieldsObject.attr("action");
      // $.ajax({
      //   type: "POST",
      //   url: url,
      //   data: { function: "getReportTemplate", id:id},
      //   dataType: "json", //dataType: "text",
      //   success: getReportTemplateCallback(that),
      // });

      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: this.result.tableUrl,
        data: {
          function: 'getReportTemplate',
          id: id,
        },
      })
          .then(function (response) {
          //handle success
          console.log(response);
          caller.reportTemplatevalue = JSON.parse(response.data.result.json);
        })
        .catch(function (response) {
          //handle error
          console.log(response);

          // }
        });
    },

    // getReportTemplateCallback(that) {
    //   fieldsObject = $(that).closest(".fields");
    //   return function (data, textStatus) {
    //     data = JSON.parse(data);
    //     loadReportTemplate(fieldsObject, data);
    //     refreshHeaderImage(fieldsObject);
    //   };
    // },
  },
};
</script>
<!--template><fieldRowHeader></fieldRowHeader>
<td colspan=3>
<script type='text/javascript' src='{getFileVersionPath("js/report.js")}'></script>
<script type='text/javascript' src='{getFileVersionPath("js/moment.js")}'></script>
{$id="scriptID"|cat:rand ( 0 , 10000 )}
<script id="{$id}">
var fieldsObject=$("#{$id}").closest(".fields");

fieldsObject.data( "reportTemplatevalue", {if isset($attributes.value)&& $attributes.value!=""}{$attributes.value}{else}""{/if} );
  window.dictionarisArray={ };
  window.dictionarisArray.3GPP_GX=v-if="typeof result.additional.diameterAttrs)}{json_encode(result.additional.diameterAttrs!='undefined'"{else}""{/if};
$(function() {
  fieldsObject.find('[name=n_reportTemplateId]').change(function () {
    that = this;
    getReportTemplateAjaxCall(that);
  });

//fieldsObject.find('[name=n_reportTemplateId]').change();
//var reportTemplate=fieldsObject.data( "reportTemplate");
  var reportTemplatevalue = fieldsObject.data("reportTemplatevalue");
  if (reportTemplatevalue == "") {
    getReportTemplateAjaxCall(fieldsObject.find('[name=n_reportTemplateId]'));
  }

  reportTemplate = reportTemplatevalue;
  if (reportTemplate != "") {
    loadReportTemplate(fieldsObject, reportTemplate);
  }
  refreshHeaderImage(fieldsObject);
  setInterval(function () {
    updateTime(fieldsObject);
  }, 1000);

});



function getReportTemplateAjaxCall(that) {
  var fieldsObject = $(that).closest(".fields");
  var url = fieldsObject.attr("action");
  $.ajax({
    type: "POST",
    url: url,
    data: { 'function': "getReportTemplate", "id": $(that).val() },
    dataType: "json",//dataType: "text",
    success: getReportTemplateCallback(that),
  });
}

function getReportTemplateCallback(that) {
  fieldsObject = $(that).closest(".fields");
  return function (data, textStatus) {
    data = JSON.parse(data);
    loadReportTemplate(fieldsObject, data);
    refreshHeaderImage(fieldsObject);
  };
} 

function getTextHtml(value,fontsArray,fontSizesArray){
  if (value==""){
    value={ name:"",value:""};
  }
  nameValue=value.name;
  if (value.name==""){
   nameValue="<input type='text'/>";
}
  //$( "#c_nameRow" ).parents().eq(2).css( "max-width", "960px" );//get the nth parent
  //var textLine='<div class="sortableElement" style="padding:3px;"><b class="fieldtitle">'+nameValue+'</b>:<span class="fieldValue">'+value.type+'</span>  <span class="fontselector">'+getFontsDiv(fontsArray,fontSizesArray)+'</span></div>';
    var textLine='<tr class="sortableElement" style="padding:3px;max-width: 100px!important;"><td></td><td style="width: 14.5em" ><b style=" " class="fieldtitle">'+nameValue+'</b>:</td><td class="fieldValue" style=""><input type="text" value="'+value.value+'" style="width:300px;"></input></td></tr>';
    return textLine;
}

function refreshHeaderImage(fieldsObject){

}

function addShowedAttribute(caller){
 var $caller=$(caller);
var $showedAttributes=$caller.closest(".addShowedAttributesDiv").find(".showedAttributes");
addShowedAttributestoDiv($showedAttributes,"");
}

function addShowedAttributestoDiv($showedAttributes,value){
    $showedAttributes.append("<div style='padding:5px;' class='showedAttributeRow' ><a href='javascript:void(0);' class='' onclick='removeShowedAttribute(this)'><i class='fas fa-trash fs16 iconRed'></i></a>"+makeSelector(window.dictionarisArray.3GPP_GX, value,"comboBox singleShowedAttribute")+"</div>");
    initializeCombobox($showedAttributes);
}

function removeShowedAttribute(caller){
  $(caller).closest(".showedAttributeRow").remove();
}
</script>

<reportAdditionalFieldsTemplate></reportAdditionalFieldsTemplate>
<div class="addShowedAttributesDiv ui-widget-content">
  <div class="includedAttr">{language('reportTemplates','showedAttributes')}</div>
<div class="showedAttributes">
  </div>
  <button class='showedAttributesButton' href='javascript:void(0);' onclick='addShowedAttribute(this);return false;' ><i class='fas fa-plus-square fs16'></i>{language('reportTemplates','addAttr')}</button>
</div>
</td>
</tr></template-->